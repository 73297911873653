import { Avatar, Col, Layout, Menu, MenuProps, Typography } from 'antd';
import { ReactComponent as MilaIcon } from '../../common/Icons/MilaIcon.svg';
import { oidcAuthService } from '../../app/services/auth/oidcAuthService';
import { useAuth } from 'react-oidc-context';
import React, { useState } from 'react';

import { Link, Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

const { Header } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const renderUserInfo = (collapsedUserMenu: boolean, userName: string) => {
  return (
    <div>
      <div className={styles.userNameContainer}>
        <Typography.Text>{userName}</Typography.Text>
        {/*{collapsedUserMenu && <Typography.Text className={styles.userDescription}>Администратор сети</Typography.Text>}*/}
      </div>
      <div>
        <Avatar shape={'circle'} src={''} size={44} className={styles.userAvatar}>
          {userName?.charAt(0).toLocaleUpperCase()}
        </Avatar>
      </div>
    </div>
  );
};

const NavBar = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const authService = oidcAuthService;
  const loggedUserName = auth.user?.profile.preferred_username || '';

  const [collapsedUserMenu, setCollapsedUserMenu] = useState(false);

  const handleLogout = async () => await authService.logout();

  const handleChangePassword = async () => (window.location.href = await authService.changeUserPasswordUrl());

  const handleRedirectToNetworkSelection = () => navigate('/networkSelection');

  const itemsUserMenu: MenuItem[] = [
    {
      key: 'userName',
      icon: renderUserInfo(collapsedUserMenu, loggedUserName),
      children: [
        {
          label: 'Сменить пароль',
          key: '2',
          onClick: () => handleChangePassword(),
        },
        {
          label: 'Сменить организацию',
          key: '3',
          onClick: () => handleRedirectToNetworkSelection(),
        },
        {
          label: <span style={{ color: '#FA6060' }}>Выйти</span>,
          key: '4',
          onClick: () => handleLogout(),
        },
      ],
    },
  ];

  return (
    <>
      <Layout className={styles.navBarContainer}>
        <Header className={styles.headerContainer}>
          <Col className={styles.logo}>
            <MilaIcon />
          </Col>
          <Col className={styles.linksContainer}>
            <Typography.Link href="mailto:support@n3health.ru" target="_blank" className={styles.LabelsWrapper}>
              Техподдержка
            </Typography.Link>
            <Typography.Link href="https://mila.online/manuals" target="_blank" className={styles.LabelsWrapper}>
              Инструкции
            </Typography.Link>
          </Col>
          <div className={styles.headerSplitter}></div>
          <Col className={styles.widgetLinksContainer}>
            <Link
              to="/"
              className={`${styles.LinkLabelsWrapper}${
                location.pathname === '/' ? ` ${styles.LinkLabelsWrapperActive}` : ''
              }`}
            >
              ТЕЛЕМЕДИЦИНСКИЕ КОНСУЛЬТАЦИИ
            </Link>
            <Link
              to="/researches"
              className={`${styles.LinkLabelsWrapper}${
                location.pathname === '/researches' ? ` ${styles.LinkLabelsWrapperActive}` : ''
              }`}
            >
              КЛИНИЧЕСКИЕ ИССЛЕДОВАНИЯ
            </Link>
          </Col>
          <Col className={styles.userInfoContainer}>
            <Menu
              className={styles.Menu}
              items={itemsUserMenu}
              triggerSubMenuAction={'click'}
              mode="horizontal"
              onOpenChange={() => setCollapsedUserMenu(!collapsedUserMenu)}
            />
          </Col>
        </Header>

        <Col>
          <Layout>
            <Outlet />
          </Layout>
        </Col>
      </Layout>
    </>
  );
};

export default NavBar;
